@import "globals";

/*

Table of content:
- VARIABLES
- IMPORTS
- COMMON CLASSES
- SELECTORS
- LANDINGPAGE SECTIONS

*/

//// VARIABLES

$var-font-segoe: 'Segoe UI', 'Open Sans', Helvetica, sans-serif;
$var-font-segoe-semibold: 'Segoe UI Semibold', 'Open Sans', Helvetica, sans-serif;
$var-font-segoe-bold: 'Segoe UI Bold', 'Open Sans', Helvetica, sans-serif;
$var-font-segoe-light: 'Segoe UI Light', 'Open Sans', Helvetica, sans-serif;

$var-color-background-light: #F2F2F5;
$var-color-background-dark: #4260D0;
$var-color-background-darker: #102052;
$var-color-lighter: #D0D7F0;
$var-color-text: #333;
$var-color-text-light: #636369;
$var-color-text-lighter: #7a829e;
$var-color-accent: #E16E57;
$var-color-green: #31AF6E;

$var-screen-small: 576px;
$var-screen-medium: 768px;
$var-screen-large: 992px;

//// IMPORTS

//@import "../resources/bootstrap-5.1.3/css/bootstrap.css";
@import "~bootstrap/scss/bootstrap";

.bg-primary{
  background-color: $var-color-accent !important;
}
.btn-secondary{
  background-color: $var-color-background-dark;
  border: none;
}
.card{
  border: none;
}

.swiper{
  width: 100%;
  height: 100%;
}
.swiper-pagination{

}

.dropdown-menu{
  display: block;
}

//// COMMON CLASSES

.app-horizontal-stack-right{
  margin-left: auto;
  margin-right: 0px;
  text-align: right;
  // & > *{
  //     display: inline-block;
  //     margin-left: 16px;
  //     margin-right: 16px;
  // }
}

.app-list{
  display: flex;
  width: 100%;
  justify-content: stretch;
  list-style: none;
}

.app-list-right{
  display: flex;
  width: 100%;
  justify-content: right;
}
.app-list-left{
  width: 100%;
  display: flex;
  justify-content: left;
}
.app-list-bottom{
  display: flex;
  justify-content: end;
  height: 100%;
  flex-direction: column;
}
.app-list-top{
  display: flex;
  justify-content: start;
  height: 100%;
  flex-direction: column;
}


.app-text-center{
  text-align: center;
}
.app-text-right{
  text-align: right;
}

.app-text-error{
  color: red;
}

.app-color-background-light{
  background-color: $var-color-background-light;
}
.app-color-background-dark{
  background-color: $var-color-background-dark;
}
.app-color-background-darker{
  background-color: $var-color-background-darker;
}
.app-color-background-accent{
  background-color: $var-color-accent;
}
.app-color-text{
  background-color: $var-color-text;
}
.app-color-text-light{
  background-color: $var-color-text-light;
}
.app-color-text-lighter{
  background-color: $var-color-text-lighter;
}
.app-color-accent{
  background-color: $var-color-accent;
}
.app-color-dark{
  color: $var-color-background-dark;
}

.app-font-large{
  font-size: larger;
}
.app-font-larger{
  font-size: x-large;
}
.app-font-medium{
  font-size: medium;
}
.app-font-small{
  font-size: small;
}
.app-font-boldy{
  font-weight: 350;
}


.app-container{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.app-width-100{
  width: 100%;
}
.app-width-80{
  width: 80%;
}
.app-width-50{
  width: 50%;
}

.app-margin-bottom-8px{
  margin-bottom: 8px;
}
.app-margin-left-right{
  margin-right: 16px;
  margin-left: 16px;
}
.app-margin-top-bottom{
  margin-top: 16px;
  margin-bottom: 16px;
}
.app-margin-top-32{
  margin-top: 32px !important;
}
.app-margin-bottom-32{
  margin-bottom: 32px !important;
}
.app-margin-top{
  margin-top: 16px !important;
}
.app-margin-right{
  margin-right: 32px;
}

.app-padding{
  padding: 16px;
}
.app-padding-bottom {
  padding-bottom: 48px;
}
.app-padding-clean-8px{
  padding: 5px 8px 5px 8px;
}
.app-padding-top-bottom{
  padding-top: 16px;
  padding-bottom: 16px;
}

.app-center{
  margin: auto;
}
.app-center-text{
  text-align: center;
}

.app-colors-accent{
  background-color: $var-color-accent !important;
  color: white;
  font-size: large;
}

.app-grid{
  @extend .row;
}
.app-grid-column{
  &-1{
    @extend .col-1;
  }
  &-2{
    @extend .col-2;
  }
  &-3{
    @extend .col-3;
  }
  &-4{
    @extend .col-4;
  }
  &-5{
    @extend .col-5;
  }
  &-6{
    @extend .col-6;
  }
  &-7{
    @extend .col-7;
  }
  &-8{
    @extend .col-8;
  }
  &-9{
    @extend .col-9;
  }
  &-10{
    @extend .col-10;
  }
  &-11{
    @extend .col-11;
  }
  &-12{
    @extend .col-12;
  }
}

//.app-col-half {
//  flex: 0 0 auto;
//  width: 50%;
//}
//.app-grid-column-full{
//  flex: 0 0 auto;
//  width: 100%;
//}

.app-col-1third{
  flex: 0 0 auto;
  width: 33.33333333%;
}

.app-bottom{

}

.app-height-full{
  height: 100%;
}

.app-width-full{
  width: 100%;
}

.app-justify-right{
  justify-content: right;
}

//// SELECTORS

html, body{
  font-family: $var-font-segoe;
  font-weight: 300;
}
body{
  width: 100%;
  font-size: 1.1em;
}
h1, h2, h3, h4, h5, h6{
  font-family: $var-font-segoe;
  font-weight: 300;
}
h1{
  font-size: 1.9em;
}
h2{
  font-size: 1.6em;
}
h3{
  font-size: 1.3em;
}
h4{
  font-size: 1.2em;
}

button{
  @extend .app-button;
}

label{
  font-weight: 350;
}

a{
  text-decoration: none;
  color: inherit;
  &:visited{
    color: $var-color-text;
  }
  &:hover{
    color: $var-color-background-dark;
  }
}

b, strong{
  font-weight: 350;
}

s{
  text-decoration-thickness: 0.07em;
}

//// COMPONENTS

.app-link-light:hover{
  color: $var-color-background-dark;
}

.app-dropdown-button:hover{

}

.app-button{
  @extend .btn;
  @extend .btn-primary;
  @extend .app-color-background-dark;
  border-color: transparent;
  color:white;
}

.app-form-input{
@extend .form-control;
   @include set-placeholder {
     opacity: 0.7;
   };
}

.app-horizontal{
  @extend .row;
}
.app-horizontal-item{
  @extend .col;
}

.app-carousel-item{

}

.app-modal{
  @extend .modal;
}
.app-modal-dialog{
  @extend .modal-dialog;
}
.app-modal-content{
  @extend .modal-content;
}
.app-modal-header{
  @extend .modal-header;
}
.app-modal-title{
  @extend .modal-title;
}
.app-modal-body{
  @extend .modal-body;
}
.app-modal-footer{
  @extend .modal-footer;
}

//// LAYOUT

$var-navbar-height: 90px;

.navbar-language{
  background-color: transparent;
  color: $var-color-text;
  border: none;
}

#app-header{
  position: sticky;
  z-index: 1000;
  width: 100%;
  top: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  @extend .app-color-background-light;
  & > *{
    @extend .app-container;
    margin-bottom: 10px;
    padding: 0px;
  }
}

#app-footer{
  background-color: $var-color-lighter;
  min-height: 300px;
  color: $var-color-text;
}

.visibility-hidden {
  display: none;

}

#footer{
  @include on-mobile {
    display: none;
  };
}
#app-footer-right{
  text-align: end;
}
.app-footer-bottom{
  @extend .app-height-full;
  display: flex;
  align-items: flex-end;
}
#footer-mobile{
  @include on-desktop {
    display: none;
  }
}
#footer-mobile-rules{
  margin-top: 16px;
  margin-bottom: 70px;
}

//// LANDINGPAGE SECTIONS

$var-landingpage-section-minheight: 400px;

.landingpage-section{
  padding-top: 60px;
  padding-bottom: 60px;
  vertical-align: middle !important;
}

#landingpage-cta-container{
  @extend .landingpage-section;
  @extend .app-color-background-light;
}

#cta-grid{
  @extend .app-grid;
  & > * {
    @include on-desktop {
      flex: 0 0 auto;
      width: 50%;
    };
    @include on-mobile {
      flex: 0 0 auto;
      width: 100%;
    };
  }
}

.cta-heading {
  white-space: break-spaces;
  padding-bottom: 36px;
}

.cta-widget{
  margin-top: 4em;
  margin-bottom: 2em;
}
#cta-popup-button{
  background-color: $var-color-green;
  color: white;
  font-size: large;
}
.cta-widget-button{
  display: block;
  background-color: $var-color-green;
  color: white;
  font-size: x-large;
  margin-left: auto;
  margin-right: auto;
}

#ctaPopupAgreement{
  font-weight: 400;
  margin: 32px;
  & > a{
    margin-left: 16px;
  }
}

#landingpage-introduction-container{
  @extend .landingpage-section;
  background-color: white;
}
#introduction-carousel{

}
.introduction-element{
  @include on-desktop {
    flex: 0 0 auto;
    width: 50%;
  };
  @include on-mobile {
    flex: 0 0 auto;
    width: 100%;
  };
}

#landingpage-media-container{
  background-color: white;
}
#media-list{
  @extend .app-grid;
  & > div{
    @include on-desktop {
      flex: 0 0 auto;
      width: 50%;
    };
  @include on-mobile {
    flex: 0 0 auto;
    width: 100%;
  };
  & > p{
  margin-top: -16px;
  }
  }
}

#landingpage-timer{
  @extend .landingpage-section;
  @extend .app-color-background-dark;
  color:white;
}

#timer{
  width: 60%;
  margin: auto;
  @include on-mobile {
    display: none;
  };
}
#timer-watch{
  font-size: 4em;
}
#timer-mobile{
  width: 90%;
  margin: auto;
  @include on-desktop {
    display: none;
  };
}
.timer-watch-mobile{
  font-size: 2em;
}
.timer-watch-captions{
  font-size: x-large;
  font-weight: 350;
}

#timer-promotionslist{
  @include on-mobile {
    font-size: 0.8em;
  };
  margin-left: -1em;
  margin-right: -1em;
}
.timer-promotionlist-item{
  padding: 0px;
  text-align: center;
}
.timer-currentpromo{
  font-size: x-large;
  font-weight: 350;
  margin-left: 8px;
}

#landingpage-buisnessinfo-container{
  @extend .landingpage-section;
  background-color: white;
}
.buisnessInfoSection-title{
  @extend .app-color-background-dark;
  @extend .app-padding-clean-8px;
  color: white;
  font-size: 1.2em;
}
.businessInfoSection-caption{
  font-size: 1.1em;
}
#buisnessInfoSection-column{
  @media (max-width: $var-screen-medium){
    //Cannot extend classes within media query. This is copied from boostrap col-12 class
    flex: 0 0 auto;
    width: 100%;
  }
  @media (min-width: $var-screen-medium){
    flex: 0 0 auto;
    width: 50%;
  }
  @media (min-width: $var-screen-large){
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
#businessinfo-sections{
  @extend .app-grid;
}

#landingpage-valueadded-container{
  @extend .landingpage-section;
  background-color: white;
}
.valueadded-listrecord{
  @media (max-width: $var-screen-medium){
    //Cannot extend classes within media query. This is copied from boostrap col-12 class
    flex: 0 0 auto;
    width: 100%;
  }
  @media (min-width: $var-screen-medium){
    flex: 0 0 auto;
    width: 50%;
  }
  padding: 16px 8px;
  line-height: 1.7;
}


#landingpage-roadmap-container{
  @extend .landingpage-section;
  background-color: white;
}
#roadmap-timeline{
  @extend .app-width-full;
  height: 0.25em;
  background-color: $var-color-accent;
  border-radius: 5px;
}
.roadmap-segment-title{
  @include on-mobile {
    font-size:1.2em;
  };
}

#landingpage-socialproof-container{
  @extend .landingpage-section;
  background-color: white;
}

#landingpage-staytuned-container{
  @extend .landingpage-section;
  background-color: white;
}

#landingpage-makersteam-container{
  @extend .landingpage-section;
  background-color: white;
}

#landingpage-makersteam-container {
  @extend .landingpage-section;
  background: none;
  color: #FFF;
}
