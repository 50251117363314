@import "../../../scss/globals";

// TYPOGRAPHY
.pricing-title-text {
  @include text-type(600, 32px, 38px);
  @include on-mobile {
    @include text-type(600, 20px, 38px);
  }
}

.pricing-highlight-heading-text {
  @include text-type(600, 20px, 28px);
  @include on-mobile {
    @include text-type(600, 16px, 28px);
  }
}

.pricing-highlight-text {
  @include text-type(600, 20px, 28px);
  @include on-mobile {
    @include text-type(600, 12px, 28px);
  }
}

.pricing-standard-text {
  @include text-type(400, 20px, 28px);
  @include on-mobile {
    @include text-type(400, 12px, 28px);
  }
}

.pricing-additional-info-text {
  @include text-type(600, 14px, 24px);
}

.pricing-main-price {
  @include text-type(600, 64px, 28px);
  @include on-mobile {
    @include text-type(600, 40px, 28px);
  }
}

.pricing-highlight-price {
  @include text-type(600, 40px, 28px);
  @include on-mobile {
    @include text-type(600, 40px, 28px);
  }
}

.pricing-regular-price {
  @include text-type(400, 40px, 28px);
  @include on-mobile {
    @include text-type(400, 40px, 28px);
  }
}


.pricing-container {
  background-color: #4160D0;
  color: #ffffff;
  white-space: break-spaces;
  @include on-desktop {
    background: #4160D0 url("./pricing-styling-icons.svg") no-repeat bottom center;
    padding: 125px 0 378px 25px;
  }
  @include on-mobile {
    padding: 20px 0 18px 17px;
  }
  @include on-tablet {
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 300px 25px;
    @include centered;
  }

  .pricing-wrapper {
    @include on-ultra {
      gap: 30px;
      justify-content: center;
      @include centered;
    };
  }
  .pricing-heading {
    @include on-tablet {
      padding-bottom: 30px;
    }
    @include on-ultra {
      padding-bottom: 65px;
      display: flex;
      justify-content: center;
    }
  }

  .pricing-info-wrapper {
    display: flex;
    @include on-ultra {
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include on-mobile {
      padding-bottom: 13px;
      border-bottom: 1px solid #ffffff;
    }
    @include on-tablet {
      padding-bottom: 13px;
      border-bottom: 1px solid #ffffff;
      display: flex;
      align-items: end;
    }

    .pricing-cost {
      display: flex;
      align-items: baseline;
      @include on-mobile {
        padding-left: 80px;
      }
    }
  }

  .fixed-width {
    @include on-desktop {
      width: 200px;
    }
    @include on-mobile {
      width: 110px;
    }
  }

  .price-spacing {
    padding-right: 2px;
    @include on-tablet {
      padding-left: 30px;
    }
  }

  .pricing-contractors-offers {
    gap: 30px;
    padding: 10px 0;
    @include centered;
  }

  .pricing-contractor-no-icon {
    margin-left: 149px;
  }

  .pricing-transaction-section-wrapper {
    @include on-mobile {
      border-bottom: 1px solid #ffffff;
    }
    @include on-tablet {
      border-bottom: 1px solid #ffffff;
    }

    .pricing-transaction-heading {
      @include on-ultra {
        padding-left: 30px;
      }
    }

    .pricing-transaction-selector {
      @include on-ultra {
        padding: 0 30px 20px 30px;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
      }
    }
  }

  .pricing-section-wrapper {
    padding: 32px 0 32px 0;
  }

  .net-prices {
    display: flex;
    @include on-desktop {
      padding-right: 20px;
    }
    @include on-ultra {
      justify-content: center;
      padding-right: 20px;
    }
    @include on-mobile {
      justify-content: end;
      padding: 0 23px 15px 0;
    }
  }
}