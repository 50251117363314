.makersteam-wrapper {
  display: grid;
  grid-template: 1fr / 1fr;
  & > * {
    grid-area: 1 / 1 / 1 / 1;
    position: relative;
  }
  overflow: hidden;
}

.makersteam-background {
  width: 100%;
  height: 100%;
  background-color: #6880D9;
}

.makersteam-background-circle {
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.makersteam-title {
  margin-bottom: 50px;
  font-weight: 350;
}

$makersteam-list-column-width: 150px;
$makersteam-list-gap: 50px;
$makersteam-list-max-col-number: 3;

.makersteam-list-wrapper {
  max-width: ($makersteam-list-column-width + $makersteam-list-gap) * $makersteam-list-max-col-number - $makersteam-list-gap;
  margin: 0 auto;
}

.makersteam-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, $makersteam-list-column-width);
  gap: $makersteam-list-gap;
  justify-content: center;

  &-item {
    text-align: center;

    &-picture {
      width: 100px;
      height: 100px;
      box-shadow: #fff 0px 0px 0px 2px;
      background-color: #fff;
      border-radius: 50%;
      box-sizing: content-box;
    }

    &-name {
      font-weight: 400;
    }

    &-post {
      font-size: 0.8em;
      white-space: pre-wrap;
    }
  }
}
