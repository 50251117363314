//// FUNCTIONS
@mixin on-desktop(){
  @media screen and (min-width: 600px) { @content; }
}

@mixin on-tablet() {
  @media screen and (min-width: 600px) and (max-width: 1280px) { @content; }
}

@mixin on-mobile(){
  @media screen and (max-width: 600px) { @content; }
}

// Mixin for ultra width screens
@mixin on-ultra() {
  @media screen and (min-width: 1281px) { @content; }
}

@mixin set-placeholder(){
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    @content;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    @content;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    @content;
  }
}

@mixin centered() {
  display: flex;
  align-items: center;
}

@mixin text-type($fontWeight, $fontSize, $lineHeight) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}